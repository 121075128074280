@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Noto Serif Display Italic";
    src: url("fonts/NotoSerifDisplay-Italic.woff2");
}

@font-face {
    font-family: "Le Jour Serif";
    src: url("fonts/Le Jour Serif.woff2");
}

@font-face {
    font-family: "Open Sans Regular X";
    src: url("fonts/OpenSans-Regular.woff2");
}

@font-face {
    font-family: "Open Sans Light X";
    src: url("fonts/OpenSans-Light.woff2");
}

.fade {
    width: 100%;

    background-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0) 100%);
}
