@tailwind components;

@layer components {
  .btn-landing-primary {
    @apply uppercase font-light bg-primary text-4xl py-4 w-full rounded-lg md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear;
  }

  .btn-landing-secondary {
    @apply py-3 text-center uppercase font-light bg-white text-black w-full rounded-lg md:hover:rounded-3xl md:hover:bg-gray-300 md:transition-all md:duration-75 md:ease-linear;
  }

  .btn-landing-tertiary {
    @apply py-3 ring-2 ring-red-500 text-center uppercase font-light text-xl w-full rounded-lg md:hover:rounded-3xl md:transition-all md:duration-75 md:ease-linear bg-tertiary md:hover:bg-tertiary-hover;
  }

  .sticky-nav {
    @apply shadow md:top-0 md:sticky flex flex-col md:flex-row md:space-x-3 items-center justify-center text-center font-light text-2xl py-2.5 md:py-4 bg-gray-100;
  }
}